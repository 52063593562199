* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main {
    padding:45px;
    width:100%;
    height:100vh;
    justify-content: center;
    align-items: center;
    z-index: -2;
    position: relative;
}

.proficient {
    background-color: white;
    text-align: center;
    margin-bottom: 10vh;
    z-index: -1;
}

.proficient h2 {
    margin-bottom: 20px;
    z-index: -1;
}

.experience {
    background-color: white;
    text-align: center;
    margin-bottom: 10vh;
    z-index: -1;
}

.experience h2 {
    margin-bottom: 20px;
    z-index: -1;
}

.icons {
    justify-content: center;
    display: flex;
    z-index: -1;
}

.learning {
    background-color: white;
    text-align: center;
    z-index: -1;
}

.learning h2 {
    margin-bottom: 20px;
}

.icons img {
    width: 20%;
    min-width: 40px;
    z-index: -1;
}

.imgCaption {
    background-color: lightcyan;
    z-index: -1;
}
