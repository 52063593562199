@import url("https://fonts.googleapis.com/css2?family=Schibsted+Grotesk&display=swap");

* {
  box-sizing: border-box;
  font-family: 'Schibsted Grotesk', sans-serif;
}

body {
  margin: 0;
  background-color: white;
  font-family: 'Schibsted Grotesk', sans-serif;
}

h1 {
  font-size: 2.5rem;
  color: #2c292b;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
}
