@import url("https://fonts.googleapis.com/css2?family=Schibsted+Grotesk&display=swap");

.updating {
    align-items: center;
    justify-content: center;
    align-self: center;
    text-align: center;
    border-color: black;
    border-width: 10px;
    font-size: 60px;
    padding-top: 50px;
    font-weight: bold;
}