* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  padding:45px;
  width:100%;
  height:100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card {
    box-shadow: 0 4px 8px 0 black;
    transition:0.5s;
    width:400px;
    max-width: 400px;
    min-width: 400px;
    max-height: 560px;
    text-align: left;
    float: left;
    margin:10px;
    padding: 5px;
    background-color: lightcyan;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.card img {
    max-height: 300px;
    min-width: 300px;
}

.cardContent {
    margin: 10px;
    line-height: 2em;
    background-color: white;
    padding: 10px;
    max-height: 326px;
    min-height: 290px;
}

.madeIcons img {
    justify-content: space-between;
    max-width: 15%;
    min-width: 15%;
    padding: 5px;
}

.award img {
    height: 20%;
    resize:both;
}


.button {
    appearance: button;
    background-color: lightcyan;
    background-image: none;
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 10px 10px 10px 0;
    overflow: visible;
    padding: 12px 40px;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .button:focus {
    text-decoration: none;
  }
  
  .button:hover {
    text-decoration: none;
    background-color: black;
    color: white;
  }
  
  .button:active {
    box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
    outline: 0;
  }
  
  .button:not([disabled]):active {
    box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
    transform: translate(2px, 2px);
  }
  
  @media (min-width: 768px) {
    .button {
      padding: 5px 30px;
    }
  }